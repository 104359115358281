import React from 'react'
import classNames from 'classnames'
import * as styles from './categoryPill.module.css'

const CategoryPill = ({ category }) => {
  const classes = {
    [styles.categoryLabel]: true,
    [styles[`category${category}`]]: true,
  }

  return <div className={classNames(classes)}>{category}</div>
}

export default CategoryPill
