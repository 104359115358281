import React from 'react'
import { Link, graphql } from 'gatsby'
import moment from 'moment'
import SEO from '../components/seo'
import Layout from '../components/layout'
import BackButton from '../components/backButton'
import * as styles from './templates.module.css'
import Icon from '../components/icons/icon'
import Callout from '../components/callout'
import CategoryPill from '../components/categoryPill'
import { getTextCopy, getHtmlCopy } from '../helpers'

import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'

const Article = ({ data }) => {
  const {
    slug,
    title,
    excerpt,
    articleContent,
    category,
    tags,
    author,
    relatedMedia,
    relatedArticles,
    crossPost,
  } = data.article
  const { seoImage, pageSlug, content, callout } = data.articlePage
  const { learnPageSlug } = data.learnPage

  const fullSlug = pageSlug.replace(`{{slug}}`, slug)

  const extraMeta = [
    {
      property: `og:type`,
      content: `article`,
    },
    {
      property: `article:section`,
      content: category,
    },
  ]

  ;(tags || []).map((tag) =>
    extraMeta.push({
      property: `article:tag`,
      content: tag.title,
    })
  )

  const extraLink = []
  if (crossPost && crossPost.url) {
    extraLink.push({
      rel: `canonical`,
      href: crossPost.url,
    })
  }

  const renderRelatedArticleImage = (relatedArticle) => {
    const img = getImage(relatedArticle.image)
    const bgImage = convertToBgImage(img)

    return (
      <BackgroundImage {...bgImage}>
        <div>
          <GatsbyImage
            className={styles.image}
            image={img}
            alt={`${relatedArticle.title} cover`}
          />
        </div>
      </BackgroundImage>
    )
  }

  return (
    <>
      <SEO
        title={title}
        description={excerpt}
        imageUrl={seoImage.file.url}
        urlPath={fullSlug}
        extraMeta={extraMeta}
        extraLink={extraLink}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <div className={styles.articleWrapper}>
          <div className={styles.backButtonContainer}>
            <Link to={learnPageSlug}>
              <BackButton>
                {getTextCopy(content, 'page.article.backButton')}
              </BackButton>
            </Link>
          </div>
          <div className={styles.pageWrapper}>
            <div className={styles.articleHeaderWrapper}>
              <CategoryPill category={category} />
              <div className={styles.articleHeader}>
                <h1 className={styles.postTitle}>{title}</h1>
                <p className={styles.snippet}>{excerpt}</p>
              </div>
              <div className={styles.articleMetaWrapper}>
                <div className={styles.articleMeta}>
                  <div className={styles.readTimeContainer}>
                    <p className={styles.readTime}>
                      <Icon icon="Clock" />
                      {getTextCopy(content, 'page.article.minuteRead')
                        .replace(`{{readTime}}`, articleContent.child.timeToRead)}
                    </p>
                  </div>
                  {tags && (
                    <>
                      <span className={styles.bullet}>&bull;</span>
                      <div className={styles.articleTags}>
                        {tags.map((tag, i, tags) => (
                          <div key={tag.id} className={styles.tag}>
                            {tag.title}
                            {tags.length - 1 !== i && <span>,&nbsp;</span>}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                {crossPost && (
                  <div className={styles.crossPostWrapper}>
                    {crossPost.mediaOutlet && (
                      <div className={styles.mediaOutlet}>
                          <GatsbyImage
                            image={crossPost.mediaOutlet.logo.gatsbyImageData}
                            alt={crossPost.mediaOutlet.name}
                            style={{ borderRadius: 4 }}
                          />
                      </div>
                    )}
                    <div className={styles.crossPostDetails}>
                      <div                
                        dangerouslySetInnerHTML={{
                          __html: getHtmlCopy(content, 'page.article.originallyPublished')
                            .replace(`{{sourceName}}`, crossPost.mediaOutlet.name)
                            .replace(`{{publishedOn}}`, moment(crossPost.publishedOn).format("MMMM Do, YYYY"))
                            .replace(encodeURIComponent('{{sourceUrl}}'), crossPost.url)
                            .replace(`\n`,`<br/>`),
                          }}
                        />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.pageWrapper}>
            <div
              className={styles.html}
              dangerouslySetInnerHTML={{
                __html: articleContent.child.html,
              }}
            />
            {relatedMedia && (
              <p className={styles.html}>
                <a href={relatedMedia.file.url}>
                  {getTextCopy(content, 'page.article.downloadLinkText')}
                </a>
              </p>
            )}
            {author && (
              <div className={styles.authorWrapper}>
                <div className={styles.authorAvatar}>
                  <GatsbyImage
                    image={author.photo.gatsbyImageData}
                    alt={`${author.firstName} ${author.lastName}`}
                  />
                </div>
                <div className={styles.authorInfo}>
                  <div className={styles.authorName}>
                    {author.firstName} {author.lastName}
                  </div>
                  <div className={styles.authorTitle}>{author.jobTitle}</div>
                  <div className={styles.authorBio}>{author.bio}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        {relatedArticles && (
          <div className={styles.relatedArticlesWrapper}>
            <hr />
            <div className={styles.relatedArticlesTitle}>
              {getTextCopy(content, 'page.article.relatedArticlesHeader')}
            </div>
            <div className={styles.relatedArticlesContainer}>
              {(relatedArticles || []).map((relatedArticle, i) => (
                <div
                  key={relatedArticle.id}
                  className={styles.relatedArticleCard}
                >
                  <div className={styles.thumbnail}>
                    <Link
                      to={pageSlug.replace(`{{slug}}`, relatedArticle.slug)}
                    >
                      {renderRelatedArticleImage(relatedArticle)}
                    </Link>
                  </div>
                  <div className={styles.content}>
                    <CategoryPill category={relatedArticle.category} />
                    <div className={styles.title}>
                      <Link
                        to={pageSlug.replace(`{{slug}}`, relatedArticle.slug)}
                      >
                        {relatedArticle.title}
                      </Link>
                    </div>
                    <div className={styles.excerpt}>
                      {relatedArticle.excerpt}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Layout>
    </>
  )
}

export default Article

export const pageQuery = graphql`
  query articleQuery($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      slug
      title
      excerpt
      articleContent: content {
        child: childMarkdownRemark {
          html
          timeToRead
        }
      }
      category
      tags {
        id
        title
      }
      author {
        firstName
        lastName
        jobTitle
        photo {
          gatsbyImageData(
            layout: FIXED
            height: 100
            width: 100
            resizingBehavior: FILL
          )
        }
        bio
      }
      relatedMedia {
        file {
          url
        }
      }
      relatedArticles {
        id
        slug
        title
        excerpt
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
        category
      }
      crossPost {
        url
        mediaOutlet {
          name
          logo {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 64
              height: 64
            )
          }
        }
        publishedOn
      }
    }
    articlePage: contentfulPage(name: { eq: "page.article" }) {
      ...pageFields
    }
    learnPage: contentfulPage(name: { eq: "page.learn" }) {
      learnPageSlug: slug
    }
  }
`
